/* You can add global styles to this file, and also import other style files */
@import url("https://bluekcmemberportal.azureedge.net/css/style-guide.css");
@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
main { text-align:center; padding:26px 0 0 0; }
header { margin:0 auto 24px auto; width:1025px; display:inline-block;  }
section { width:1025px; margin:0 auto; text-align:left; display:inline-block; }
p { color:var(--gray-5); margin:0 0 16px 0; }
.last { margin:0 0 0 0; }
button { text-transform:none!important; width:375px; margin:0 0 16px 0; }
button.link-button { background:none; color:var(--blue); padding:15.5px 40px; }
button.secondary-button { border:solid 2px var(--blue); font-weight:600;}
button.danger-button { background-color: #CA4848; color:var(--white);}
button.last { margin:0 0 0 0; }
button:disabled { color:var(--gray-5)!important; background-color:var(--gray-3)!important; background-color:#d1d5db!important; color:#6b7786!important; cursor:not-allowed; }
h1 { font-weight:600; font-size:24px; line-height:32px; text-align:left; margin:0 0 16px 0; }
h1 span.help-messaging { display:block; float:left; margin:0 8px 0 0; }
h1 span.help-circle { display:block; float:left; position:relative; }
h2 { font-size:16px; line-height:24px; font-weight:600; }
.col-left {
    display:inline-block;
    margin:0 16px 0 0;
    vertical-align:top;
    width:369px;
}
.col-right {
    display:inline-block;
    width:640px;
    position:relative;
}

ul.breadcrumb {
  list-style:none;
  margin:0 0 21px 0;
  padding:0;
  display:inline-block;
}
ul.breadcrumb li {
  display:block;
  float:left;
  margin:0 17px 0 0;
}
ul.breadcrumb li a, ul.breadcrumb li {
  color:var(--gray-5);
  text-decoration:underline;
  font-size:14px;
  font-weight:600;
/* BUTTONS */
}
ul.breadcrumb li {
  font-weight:400;
  text-decoration:none;
}
button.link-button.back {
  text-align:left;
  padding:15.5px 0;
}
a.read-more {
  color:var(--blue);
  cursor: pointer;
  font-size:14px;
  font-weight:600;
  text-decoration:none;
}
button.gray-arrow {
    background:none;
    width:24px;
    height:24px;
    margin:0;
    padding:0;
}
button.remove {
    border:none;
    background:none;
    padding:0;
    width:auto;
    margin:0;
}
/* p {
    color:#6B7786;
    font-size:16px;
    line-height:24px;
    margin:0 0 16px 0;
} */
p.error-text, .error-text {
  font-size:14px!important;
  line-height:16px!important;
  margin:0 0 8px 0!important;
  color:var(--red);
}
.input-search {
  display:inline-block;
  margin:0 0 16px 0;
  border:solid 1px var(--gray-6);
  border-radius:4px;
  width:100%;
}
input[type=text]:focus, input[type=text]:active, input[type=password]:focus, input[type=password]:active, input[type=tel]:active, input[type=tel]:focus { border:solid 2px var(--blue)!important; }

.input-search span svg { fill:var(--gray-5); }
.input-search:hover span svg { fill:var(--blue); }
.input-search input {
  margin:0;
  border:none;
  width:85%;
  display:block;
  float:left;
}
.input-search span {
  display:block;
  float:right;
  margin:12px 10px 0 0;
  cursor:pointer;
}
.form-input {
  margin:0 0 16px 0;
}
select { background:var(--white); }
.last { margin:0!important; }
.clear { clear:both; }
.clear.mobile { display:none; clear:both; }
.snackbar {
  max-width: 70% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 1rem !important;
  padding: 10px !important;
}
.snackbar .mat-button-wrapper {
  color:  #73B7DB !important;
}
.snackbar  .mat-simple-snackbar-action {
  max-width: 22% !important;
  margin: 0;
}
.snackbar  .mat-simple-snackbar-action button {
  max-width: fit-content !important;
}
img.provider-photo {
  width:88px !important;
  height:88px !important;
  border-radius:100% !important;
}

/*TABLET */
@media only screen and (min-width: 798px) and (max-width:1024px) {
    main { padding:26px 40px 0 40px; }
    .col-right { position:relative; }
    header { width:100%; }
    section { width:100%; margin:0 auto; text-align:left; display:inline-block;  }
    article.headline { display:none; }
    article { width:640px; margin: 0 auto; }
    button.link-button.back { text-align:left; padding:15.5px 0; }
}
/*TABLET PORTRAIT */
@media only screen and (min-width: 415px) and (max-width:797px) {
    main { padding: 26px 40px 0 40px; }
    header, section { width:100%; margin:0 auto; text-align:left; display:inline-block; }
    header { margin:0 auto 24px auto; }
    article { width:100%!important; }
    article.headline { display:none!important; }
    .col-right { width:100%!important; position:relative; }
    button { width:375px; margin:0 0 16px 0!important; }
    .action-items { position:fixed; bottom:0; left:0; padding:20px 20px 10px 20px; text-align:center; width:90%; background-color:#FFFFFF; opacity:0.8; }
    .action-items button.back { text-align:center; }
    .action-items p { text-align:left; }
    .action-items a { text-decoration:none; text-align:center; }
    button.link-button.back { text-align:center; padding:15.5px 40px; }
    .clear.mobile { display:block; clear:both; }
    footer { display:none; }
}
/*MOBILE*/
@media only screen and (min-width: 320px) and (max-width:414px) {
    main { padding:26px 20px 20px 20px; }
    section { width:100%; margin:0 auto; text-align:left; display:inline-block; }
    header { width:100%; margin:0; }
    /* header img, header a { display:none!important; }
    header img { display:block; } */
    header img.logo { display:block!important; }
    header #menu a { display:block!important; }
    article.headline { display:none!important; }
    .col-left { display:none; }
    .col-right { width:100%; text-align:center; }
    .col-right p, .col-right label { text-align:left; }
    form select, select { width:97%!important; background:var(--white); }
    button { width:100%; margin:0 0 16px 0!important; }
    button.link-button.back { text-align:center; padding:15.5px 40px; }
    .action-items { position:fixed; bottom:0; left:0; padding:20px 20px 10px 20px; text-align:center; width:90%; background-color:#FFFFFF; opacity:0.8; }
    .action-items p { text-align:left; }
    .action-items a { text-decoration:none; text-align:center; }
    .container { min-height:1200px; }
    .clear.mobile { display:block; clear:both; }
    footer { display:none; }
}
 @media only screen and (max-width:319px) {
  main { padding:26px 20px 20px 20px; }
  section { width:100%; margin:0 auto; text-align:left; display:inline-block; }
  header { width:100%; margin:0; }
  header nav span.bell, header nav span.user { display:none; }
  header nav span.menu { display:block; }
  header img.logo { display:block!important; }
  header #menu a { display:block!important; }
  article.headline { display:none!important; }
  .col-left { display:none; }
  .col-right { width:100%; text-align:center; }
  .col-right p { text-align:left; }
  .col-right label { float:left; }
  form select { width:97%!important; background:var(--white); }
  button { width:100%; margin:0 0 16px 0!important; }
  button.link-button.back { text-align:center; padding:15.5px 40px; }
  .action-items { position:fixed; bottom:0; left:0; padding:20px 20px 10px 20px; text-align:center; width:90%; background-color:#FFFFFF; opacity:0.8; }
  .action-items p { text-align:left; }
  .action-items a { text-decoration:none; text-align:center; }
  .container { min-height:1200px; }
  img.provider-photo { width:66px!important; height:66px!important; }
  .input-search input { width:80%!important;}
  .clear.mobile { display:block; clear:both; }
  footer { display:none; }
}

/* AMWELL PHONE VISIT OVERWRITES */
.awcore-container { background-color:#394048!important; min-height:90vh!important; min-width:100%!important;  }
.awcore-participant-title-container-bg { background-color:transparent!important; border-radius:0!important; }
h3.awcore-participant-title, h4.awcore-participant-subtitle { color:#FFFFFF!important; font-size:18px!important; line-height:24px!important; font-weight:600!important; }
.awcore-self-container.active .awcore-local-view video { border:3px #FFFFFF solid!important; border-radius:16px!important; box-shadow:none!important; }
.awcore-disconnect-button { background-color:#CA4848!important; box-shadow:none!important; height:auto!important; padding:15.5px 40px!important; font-size:14px!important; font-weight:400!important; border-radius:4px!important; }
video#capsule { width:99%; border-radius:4px; margin:24px 0 0 0; }

.awcore-control-button { border-radius:60px!important; }

.center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

.lds-roller {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -40px;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #007CBD;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
